var abp = abp || {};
(function () {

    /* DEFAULTS *************************************************/

    var defaultOptions = {
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        padding: 0,
        toast: true,
        showCloseButton: true
    };

    /* NOTIFICATION *********************************************/

    var showNotification = function (type, message, title, options) {
        var icon = options.imageClass ? '<i class="me-2 ' + options.imageClass + '"></i>' : '';

        if (title) {
            options.title = icon + '<span class="toast-type-' + type + '">' + title + '</span>';
        }

        options.html = (title ? '' : icon) + '<span class="toast-type-' + type + '">' + message + '</span>';

        var combinedOptions = Object.assign({}, defaultOptions, options);
        const { imageClass, ...combinedOptionsSafe } = combinedOptions;

        Swal.fire( combinedOptionsSafe);
    };

    abp.notify.info = function (message, title, options) {
        showNotification('info', message, title, Object.assign({
            background: '#36a3f7',
            //background: 'rgb(151, 151, 151)', //design system colour - horrible grey colour!
            //imageClass: 'fa fa-info-circle'
        }, options));
    };

    abp.notify.success = function (message, title, options) {
        showNotification('success', message, title, Object.assign({
            //background: '#34bfa3',
            background: 'rgb(107, 183, 98)', //design system colour
            //imageClass: 'fa fa-check-circle'
        }, options));
    };

    abp.notify.warn = function (message, title, options) {
        showNotification('warning', message, title, Object.assign({
            //background: '#ffb822',
            background: 'rgb(247, 195, 82)', //design system colour
            //imageClass: 'fa fa-exclamation-triangle'
        }, options));
    };

    abp.notify.error = function (message, title, options) {
        showNotification('error', message, title, Object.assign({
            //background: '#f4516c',
            background: 'rgb(247, 82, 82)', //design system colour
            //imageClass: 'fa fa-exclamation-circle'
        }, options));
    };

})();
